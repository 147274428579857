import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Stack, Image } from "react-bootstrap";
import { BoxArrowRight } from "react-bootstrap-icons";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { fetch } from "../services/trackService";

import { Logo } from "../components";
import DatePanel from "../components/datePanel";

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const missingTrackMap =
  "https://res.cloudinary.com/hl3aa3y7t/image/upload/v1680620540/onboarding/trackmap_comingsoon_womukc.png";

const Track = () => {
  const { id } = useParams();
  const [track, setTrack] = useState(null);

  console.log("account page: ", id);

  useEffect(() => {
    const getTrack = async () => {
      try {
        if (!id) return;
        const result = await fetch(id);
        console.log("track page - result", result);
        setTrack(result);
      } catch (err) {
        console.log("track error: ", err);
      }
    };
    getTrack();
  }, [id]);

  const TrackTabs = () => (
    <Tabs>
      <TabList>
        <Tab>Events</Tab>
        <Tab>Configurations</Tab>
      </TabList>

      <TabPanel>
        <EventsPanel events={track.events} />
      </TabPanel>
      <TabPanel>
        <ConfigsPanel configs={track.track_configs} />
      </TabPanel>
    </Tabs>
  );
  const ConfigsPanel = ({ configs }) => {
    const getImageUrl = (path) => {
      return path ? JSON.parse(path).full : missingTrackMap;
    };
    return (
      <>
        <h5>configurations</h5>
        {Boolean(configs) && configs.length > 0 ? (
          configs.map((config) => {
            return (
              <Row>
                <Col className="d-flex mb-2">
                  <Image
                    src={getImageUrl(config.image_url__c)}
                    height={60}
                    width={60}
                    className="me-2"
                  />
                  <div>
                    <h4>{config.name}</h4>
                    <div>{config.length_km__c} km</div>
                  </div>
                </Col>
              </Row>
            );
          })
        ) : (
          <p>no configs yet</p>
        )}
      </>
    );
  };

  const EventsPanel = ({ events }) => {
    return (
      <>
        <h5>upcoming events</h5>
        {Boolean(events) && events.length > 0 ? (
          events.map((event) => {
            console.log("listing event: ", event);
            return (
              <Row className="align-items-center mb-2">
                <Col sm={4}>
                  <div className="d-flex">
                    <DatePanel date={event.date} />
                    <div className="ms-1">
                      <h4>{event.organizer__common_name__c}</h4>
                      <div className="fw-semibold fs-6">
                        {event.config__name} - {event.config__length} km
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={2}>
                  <h3>{USDollar.format(event.price__c)}</h3>
                </Col>
                <Col sm={3}>
                  <Stack direction="horizontal" gap={2}>
                    <Button className="btn-danger d-flex align-items-center">
                      <BoxArrowRight className="mx-1" />
                      Buy
                    </Button>
                    <Link key={event.sfid} to={`/event/${event.sfid}`}>
                      <Button className="btn-secondary">Details</Button>
                    </Link>
                  </Stack>
                </Col>
              </Row>
            );
          })
        ) : (
          <p>no events in future</p>
        )}
      </>
    );
  };

  if (!track) return <div>loading...</div>;
  return (
    <Container>
      <Row>
        <Col>
          <div className="text-uppercase title_font  py-3">track listing</div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex">
          {track.facility.track__image_url_c && (
            <Image
              src={JSON.parse(track.facility.track__image_url_c).thumb}
              height={80}
              width={80}
              className="mb-2"
            />
          )}
          <div className="ms-2">
            <p className="title_font">{track.facility.name}</p>
            {track.facility.logo_authorized__c && (
              <Logo
                url={track.facility.logo_url__c}
                background={track.facility.logo_background_color__c}
                height={50}
                width={89}
                className="ms-2"
              />
            )}
            <h2 className="title_font">{track.facility.track__name}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TrackTabs />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <h3>{track.facility.track__name}</h3>
          {Boolean(track.events) && track.events.length > 0 && (
            <EventsPanel events={track.events} />
          )}
        </Col>
      </Row> */}
    </Container>
  );
};

export default Track;
