import http from "./httpService";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const base_location = {
  latitude: "37.35",
  longitude: "-122.03",
  distance: "20000",
};

// get a single account
export const fetch = async (id) => {
  try {
    let result = await http.post(
      API_ENDPOINT + "/api/tracks/details/" + id,
      base_location
    );
    return result.data;
  } catch (error) {
    console.log("endpoint error", error);
    return { error: "some issue" };
  }
};

const trackServices = { fetch };
export default trackServices;
