import dayjs from "dayjs";

export const groupResultsByDate = (results) => {
  //   console.log("groupResultsByDate running wth", results);

  const dates_grouped = results.reduce((acc, event) => {
    let dateFacilId = event.track__facility__c;
    let eventDate = dayjs(event.date).format("MM-DD");
    let dateGroup = acc.find((item) => item.label === eventDate) || {
      label: eventDate,
      value: event.date,
      facilities: [],
    };
    //
    let dateFacil = dateGroup.facilities.find(
      (item) => item.id === dateFacilId
    ) || {
      id: dateFacilId,
      distance: event.distance,
      label: event.facility__common_name__c,
      latitude: event.facility__billinglatitude,
      longitude: event.facility__billinglongitude,
      logo: event.facility__logo_url__c,
      logo_background: event.facility__logo_background_color__c,
      num_rsvp: event.num_rsvp,
      events: [],
    };
    dateFacil.events.push(event);
    // console.log("dateGroupedFacil = ", dateFacil);

    dateGroup.facilities.find((item) => item.id === dateFacilId)
      ? dateGroup.facilities.map((item) =>
          item.id === dateFacilId ? dateFacil : item
        )
      : dateGroup.facilities.push(dateFacil);

    acc.find((item) => item.label === eventDate)
      ? acc.map((item) => (item.label === eventDate ? dateGroup : item))
      : acc.push(dateGroup);

    return acc;
  }, []);

  //   console.log("dates_grouped finshes with: ", dates_grouped);
  return dates_grouped;
};

export const groupResultsByTrack = (results) => {
  //   console.log("process payload results by track: ", results);

  const facilities_grouped = results.reduce((acc, event) => {
    let facil_id = event.track__facility__c;
    // console.log("got facil_id", facil_id);
    let facil = acc.find((item) => item.id === facil_id) || {
      id: facil_id,
      distance: event.distance,
      label: event.facility__common_name__c,
      latitude: event.facility__billinglatitude,
      longitude: event.facility__billinglongitude,
      logo: event.facility__logo_url__c,
      logo_background: event.facility__logo_background_color__c,
      num_rsvp: event.num_rsvp,
      events: [],
    };
    facil.events.push(event);
    // console.log("got facil", facil);
    acc.find((item) => item.id === facil_id)
      ? acc.map((item) => (item.id === facil_id ? facil : item))
      : acc.push(facil);
    return acc;
  }, []);

  //   console.log("or new facilities_grouped: ", facilities_grouped);
  return facilities_grouped;
};
