import React, { useContext, useEffect, useState } from "react";

const LocationContext = React.createContext();
const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  const success = (position) => {
    // status = null;
    //setLat(position.coords.latitude);
    //setLng(position.coords.longitude);
    console.log("setting", position.coords);
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };

  const error = () => {
    // status = "Unable to retrieve your location";
    console.log("location error, using default");
    setLocation({
      latitude: 34,
      longitude: -118,
    });
  };

  useEffect(() => {
    console.log("checking location");
    if (!navigator.geolocation) {
      // setStatus("Geolocation is not supported by your browser");
    } else {
      // setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }, []);

  const contextValue = { location };
  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};

const useLocationContext = () => {
  return useContext(LocationContext);
};

export { LocationProvider, useLocationContext };
