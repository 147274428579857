import React from "react";
import { Link } from "react-router-dom";
// import logo from "../assets/logo_red-bg_128-128.png";
// import ad from "../assets/02_gettheapp.png";
// import ad from "../assets/00_reserved.png";

import {
  // Button,
  // ButtonGroup,
  Container,
  Row,
  Col,
  // Stack,
} from "react-bootstrap";

function Header(props) {
  const logo =
    "https://res.cloudinary.com/hl3aa3y7t/image/upload/v1691224828/onboarding/logo_red-bg_128-128_egtqga.png";

  return (
    <Container>
      <Row>
        <Col sm={3}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Col>

        <Col className="d-flex align-items-center" sm={9}>
          {/* <Link to="/"> */}
          <div className="border ad_holder">
            <p className="title_font fs-2">your ad goes here</p>
          </div>
          {/* </Link> */}
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
