import axios from "axios";
//import auth from "./authService";
// const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// function isServerRequest(url) {
//   // console.log("checking if " + url + " is going to " + API_ENDPOINT);
//   return url.startsWith(API_ENDPOINT);
// }

// const token = localStorage.getItem("token");
// console.log("adding token to header", token);
// axios.defaults.headers.common["token"] = token;

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("logging the error", error);
  }
  return Promise.reject(error);
});

// ** NOT SENDING HEADERS IN PUBLIC PREVIEW
// axios.interceptors.request.use((config) => {
//   if (isServerRequest(config.url)) {
//     // Set the token header for requests to the server
//     // config.headers['Authorization'] = `Bearer ${token}`;
//     config.headers["token"] = token;
//   } else {
//     // Remove the token header for requests to other servers
//     delete config.headers["token"];
//   }
//   return config;
// });

const axiosObject = {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
};
export default axiosObject;
