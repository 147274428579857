import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Stack } from "react-bootstrap";
import { BoxArrowRight } from "react-bootstrap-icons";

import { fetchPublic } from "../services/eventService";
import DatePanel from "../components/datePanel";
import dayjs from "dayjs";

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const missingTrackMap =
  "https://res.cloudinary.com/hl3aa3y7t/image/upload/v1680620540/onboarding/trackmap_comingsoon_womukc.png";

const getImageUrl = (path) => {
  return path ? JSON.parse(path).full : missingTrackMap;
};
const LabelRow = ({ label, value }) => {
  return (
    <Stack direction="horizontal" gap={2}>
      <div>{label}</div>
      <div className="fw-bold ms-auto">{value}</div>
    </Stack>
  );
};
const Event = () => {
  const { id } = useParams();
  // const navigate = useNavigate();
  const [event, setEvent] = useState();

  const [useDate, setUseDate] = useState();
  const [useEndDate, setUseEndDate] = useState();

  const getEvent = useCallback(async () => {
    try {
      const result = await fetchPublic(id);
      console.log("event page - result", result);
      setEvent(result.data);
    } catch (err) {
      console.log("account error: ", err);
    }
  }, [setEvent, id]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    if (event) {
      setUseDate(dayjs(event.date__c).format("MM/DD/YYYY"));
      setUseEndDate(
        event.end_date__c ? dayjs(event.end_date__c).format("MM/DD/YYYY") : null
      );
    }
  }, [event]);

  if (!event) return <div>loading...</div>;

  const AccountPanel = ({ account }) => {
    // const handleClickAccount = (account) => {
    //   navigate("/account/" + account.id);
    // };
    return (
      <Link to={`/account/${account.id}`} className="link">
        <h3>{account.name}</h3>
      </Link>
    );
  };

  const handleEventClick = (event_url) => {
    // event.preventDefault();
    // Handle the click event here (e.g., navigate to an external website)
    window.location.href = event_url; // 'https://www.example.com'; // Replace with your external URL
  };

  return (
    <Container>
      <div className="text-uppercase title_font py-3">event listing</div>
      <Stack direction="horizontal" gap={2}>
        <DatePanel date={useDate} endDate={useEndDate} />
        <AccountPanel
          account={{
            name: event.organizer__common_name__c,
            id: event.organizer__slug__c || event.organizer__c,
          }}
        />
        <h3>@</h3>
        <AccountPanel
          account={{
            name: event.facility__common_name__c,
            id: event.facility__slug__c || event.facility__c,
          }}
        />
      </Stack>

      <Row>
        <Col>
          <LabelRow label="price" value={USDollar.format(event.price__c)} />
          {event.ticket_status__c && (
            <LabelRow label="ticket status" value={event.ticket_status__c} />
          )}
          <LabelRow label="date" value={useDate} />
          {useEndDate && <LabelRow label="end date" value={useEndDate} />}
          <LabelRow label="track name" value={event.track__name} />
          <LabelRow label="configuration" value={event.config__name} />
          <LabelRow label="direction" value={event.config__direction} />
          <LabelRow label="length(km)" value={event.config__length} />
          <LabelRow label="event format" value={event.event_format__c} />
          <LabelRow
            label="groups"
            value={JSON.parse(event.event_format_grouping__c).map((item) => {
              console.log(item);
              return <div className="text-right">{item}</div>;
            })}
          />
          {/* <div>Groups</div>
          <ul>
            {JSON.parse(event.event_format_grouping__c).map((item) => {
              console.log(item);
              return <li>{item}</li>;
            })}
          </ul> */}
          {event.notes__c && <LabelRow label="notes" value={event.notes__c} />}
        </Col>
        <Col>
          <img
            src={getImageUrl(event.config__image)}
            alt={"track map"}
            style={{
              // backgroundColor: background_color,
              objectFit: "contain",
              height: 200,
              width: 268,
            }}
          />
        </Col>
      </Row>

      {event.event_url__c && (
        // <a href={event.event_url__c} onClick={handleEventClick(event.event_url__c)}>
        <Button
          className="btn btn-danger my-5 px-5 "
          onClick={() => handleEventClick(event.event_url__c)}
        >
          <Stack direction="horizontal" gap={2}>
            <BoxArrowRight className="mx-1" />
            BUY
          </Stack>
        </Button>
        // </a>
      )}

      {/* <div>{JSON.stringify(event)}</div> */}
    </Container>
  );
};

export default Event;
