import React, { useCallback, useEffect, useRef, useReducer } from "react";
import { Link } from "react-router-dom";
import { fitBounds } from "google-map-react";
import GoogleMapReact from "google-maps-react-markers";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import { BoxArrowRight } from "react-bootstrap-icons";

import { useLocationContext } from "../context/locationContext";
import { fetchNear } from "../services/eventService";
import { groupResultsByDate, groupResultsByTrack } from "../utils/searchGroups";

import DatePanel from "../components/datePanel";
import MapMarker from "../components/mapMarker";
import SearchPanel from "../components/searchPanel";

const defaultProps = {
  center: {
    lat: 34,
    lng: -118,
  },
  zoom: 11,
};

const GOOGLE_MAPS_API_KEY = "AIzaSyC788lElF2KB9ZyBanIWjIH_FBRj3JgAv4";

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const reducer = (state, action) => {
  console.log("reducer being used - action.type", action.type);
  switch (action.type) {
    case "CHANGE_GROUP":
      return {
        ...state,
        groupBy: action.payload,
      };
    case "MAP_READY":
      return {
        ...state,
        mapReady: true,
      };
    case "MAP_MOVED":
      console.log("stopping map");
      return {
        ...state,
        mapMoving: state.groupedResults === undefined,
      };

    case "RECENTER_MAP":
      console.log("hopefully setting center and soom", action.payload);
      return {
        ...state,
        ...action.payload,
      };
    case "SEARCH_MAP_LOCATION":
      return {
        ...state,
        useMapLocation: true,
        redoSearch: true,
        showRedoSearch: false,
      };
    case "SELECT_DATE":
      return {
        ...state,
        selectedDate: action.payload,
        markers: action.payload.facilities,
        mapMoving: true, // map will move from markers change
      };
    case "SET_GROUPED_RESULTS":
      let selected_date = undefined,
        selected_markers = undefined;
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        if (state.groupBy === "date") {
          selected_date = action.payload[0];
          selected_markers = selected_date.facilities;
        } else {
          selected_markers = action.payload;
        }
      } else {
        console.log("no results - searched = true, groupedResults = null?");
      }
      return {
        ...state,
        groupedResults: action.payload,
        selectedDate: selected_date,
        markers: selected_markers,
        mapMoving: true, // map will move from markers change
      };
    case "SET_MAP_LOCATION":
      console.log("SET_MAP_LOCATION : ", action.payload);
      const [lng, lat] = action.payload; //const [lat, lng] = action.payload;
      // console.log("SET_MAP_LOCATION : map_latitude: ", action.payload.lat()); //  action.payload.lng()
      return {
        ...state,

        map_latitude: lat,
        map_longitude: lng,

        showRedoSearch: true,
      };
    case "SET_MAP_ZOOM":
      return {
        ...state,
        zoom: action.payload,
      };
    case "SET_OPTIONS":
      console.log("i got options", action.payload);
      return {
        ...state,
        options: action.payload,
      };
    case "STORE_SEARCH_RESULTS":
      return {
        ...state,
        results: action.payload?.results,
        city_name: action.payload?.location,
        searched: true,
      };
    case "TOGGLE_HOME_MARKER":
      return {
        ...state,
        showHomeMarker: !state.showHomeMarker,
      };
    case "USER_CLICKED":
      return {
        ...state,
        userClicked: true,
      };
    default:
      return {
        ...state,
      };
  }
};

const Map = () => {
  const { location } = useLocationContext();
  const mapRef = useRef(null);
  //   const [mapReady, setMapReady] = useState(false);

  const [mapState, dispatch] = useReducer(reducer, {
    center: undefined,
    city_name: undefined,
    groupBy: "date",
    mapMoving: false,
    mapReady: false,
    markers: undefined,
    options: undefined,
    redoSearch: false,
    results: undefined,
    searched: false,
    showHomeMarker: true,
    showRedoSearch: false,
    useMapLocation: false,
    zoom: 4,
  });

  const {
    center,
    city_name,
    groupBy,
    groupedResults,
    map_latitude,
    map_longitude,
    mapMoving,
    mapReady,
    markers,
    options,
    redoSearch,
    results,
    selectedDate,
    searched,
    showHomeMarker,
    showRedoSearch,
    useMapLocation,
    userClicked,
    zoom,
  } = mapState;

  const fitMarkerBounds = useCallback(() => {
    console.log("checking markers");
    if (!markers || !location || !mapReady) return;
    try {
      const bounds = new window.google.maps.LatLngBounds();
      console.log("start bounds: ", bounds);
      // start with home marker
      if (showHomeMarker) {
        bounds.extend({
          lat: location.latitude,
          lng: location.longitude,
        });
      }
      markers.forEach((marker) => {
        console.log("working with ", marker);
        bounds.extend({
          lat: marker.latitude,
          lng: marker.longitude,
        });
        // return marker.label;
      });
      console.log("google bounds ", bounds);
      const newBounds = {
        ne: {
          lat: bounds.getNorthEast().lat(),
          lng: bounds.getNorthEast().lng(),
        },
        sw: {
          lat: bounds.getSouthWest().lat(),
          lng: bounds.getSouthWest().lng(),
        },
      };
      // mapRef.current.fitBounds(bounds);
      const size = {
        width: 640, // Map width in pixels
        height: 380, // Map height in pixels
      };
      console.log("new bounds: ", newBounds);
      const { center, zoom } = fitBounds(newBounds, size);
      console.log("new center?", center);
      console.log("new zoom?", zoom);
      dispatch({
        type: "RECENTER_MAP",
        payload: { center: center, zoom: zoom },
      });
      // fitBounds(bounds, size);
      mapRef.current.fitBounds(bounds);
    } catch (error) {
      console.log("trying to access maps before loaded??");
    }
  }, [markers, location, mapReady, showHomeMarker]);

  useEffect(() => {
    if (!markers || markers.length === 0) return;
    console.log("markers changed", markers);
    setTimeout(fitMarkerBounds, 200);
    // fitMarkerBounds();
  }, [markers, fitMarkerBounds, showHomeMarker]);

  const handleEventClick = (event_url) => {
    // event.preventDefault();
    // Handle the click event here (e.g., navigate to an external website)
    window.location.href = event_url; // 'https://www.example.com'; // Replace with your external URL
  };
  const handleSearchPanelChange = (options) => {
    console.log("search panel now has ", options);
    dispatch({ type: "SET_OPTIONS", payload: options });
    // runSearch({ ...options, latitude: latitude, longitude: longitude });
  };

  const runSearch = useCallback(async () => {
    const result = await fetchNear({
      latitude: useMapLocation ? map_latitude : location.latitude,
      longitude: useMapLocation ? map_longitude : location.longitude,
      ...options,
    });
    console.log("search results: ", result);
    dispatch({
      type: "STORE_SEARCH_RESULTS",
      payload: result.data,
    });
  }, [location, options, useMapLocation, map_latitude, map_longitude]);

  useEffect(() => {
    if (!location) return;
    if (center) return;
    dispatch({
      type: "RECENTER_MAP",
      payload: {
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 7,
      },
    });
    // runSearch();
  }, [location, center]);

  useEffect(() => {
    if (!center) return;
    // dispatch({ type: "RECENTER_MAP", payload: { center, zoom } });
    if (!searched) runSearch();
  }, [center, runSearch, searched]);

  useEffect(() => {
    console.log("run search");
    if (redoSearch) runSearch();
  }, [redoSearch, runSearch]);

  useEffect(() => {
    if (!results) return;

    const grouped_results =
      groupBy === "date"
        ? groupResultsByDate(results)
        : groupResultsByTrack(results);
    dispatch({ type: "SET_GROUPED_RESULTS", payload: grouped_results });
  }, [results, groupBy]);

  // const _onDragEnd = ({ center, zoom /* , bounds, marginBounds */ }) => {
  const _onDragEnd = (map, options) => {
    if (!userClicked) return;
    console.log("deag end gets: ", map);
    console.log("deag end options: ", options);
    const { center, zoom: newZoom } = map;
    if (newZoom !== zoom) {
      dispatch({ type: "SET_MAP_ZOOM", payload: newZoom });
      return; // zoomed in or out, no map change
    }
    // this.props.onCenterChange(center);
    // this.props.onZoomChange(zoom);
    console.log("google maps gives center: ", center);
    console.log("google maps gives zoom: ", zoom);
    mapMoving
      ? dispatch({ type: "MAP_MOVED" })
      : dispatch({ type: "SET_MAP_LOCATION", payload: center });
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    // setMapReady(true);
    dispatch({ type: "MAP_READY" });
  };

  const DateGroupOptions = () => {
    if (!selectedDate) return null;
    return (
      <div className="my-3">
        {groupedResults.map((item) => {
          return (
            <Button
              className="btn btn-secondary"
              key={item.label}
              disabled={item.label === selectedDate.label}
              onClick={() => dispatch({ type: "SELECT_DATE", payload: item })}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
    );
  };

  const GroupByButtons = () => {
    return (
      <Row className="my-3">
        <Col>
          <ButtonGroup className="d-flex">
            <Button
              className="btn btn-secondary"
              onClick={() =>
                dispatch({
                  type: "CHANGE_GROUP",
                  payload: "date",
                })
              }
              disabled={groupBy === "date"}
            >
              date
            </Button>
            <Button
              className=" btn btn-secondary"
              onClick={() =>
                dispatch({
                  type: "CHANGE_GROUP",
                  payload: "track",
                })
              }
              disabled={groupBy === "track"}
            >
              track
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  };

  const MarkersList = () => {
    if (!markers) return null;
    return (
      <div>
        {markers.map((listing, index) => {
          console.log("markerlist - processing ", listing);
          return (
            <div className="my-3" key={`lisintg-${index}`}>
              <h3>{listing.label}</h3>
              <Container fluid>
                {/* {group === "date" ? ( */}
                {listing.events.map((event) => {
                  console.log("wriing event", event);
                  return (
                    <Row key={`event-${event.id}`} className="mb-2">
                      <Col sm={7} className="d-flex">
                        {groupBy === "track" && (
                          <DatePanel date={event.date__c} />
                        )}
                        <div className="ms-2">
                          <div className="fs-4 ">
                            {event.organizer__common_name__c}
                          </div>
                          <div className="fw-semibold fs-6">{`${event.track__name} - ${event.config__name}`}</div>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <h3>{USDollar.format(event.price__c)}</h3>
                      </Col>
                      <Col sm={3}>
                        <Stack direction="horizontal" gap={2}>
                          {event.event_url__c && (
                            <Button
                              className="btn-danger d-flex align-items-center"
                              onClick={() =>
                                handleEventClick(event.event_url__c)
                              }
                            >
                              <BoxArrowRight className="mx-1" />
                              Buy
                            </Button>
                          )}
                          <Link key={event.sfid} to={`/event/${event.sfid}`}>
                            <Button className="btn-secondary">Details</Button>
                          </Link>
                        </Stack>
                      </Col>
                    </Row>
                  );
                })}
              </Container>
              {/* ) : (
                <p>{listing.events.length}</p>
              )} */}
            </div>
          );
        })}
      </div>
    );
  };
  //   if (!location) return ;
  return (
    <Container>
      <Row>
        <Col>
          <div className="text-uppercase title_font py-3">event search</div>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <SearchPanel
            onChange={handleSearchPanelChange}
            onSubmit={runSearch}
          />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="displayLocationCheckbox"
              checked={showHomeMarker}
              onChange={() => dispatch({ type: "TOGGLE_HOME_MARKER" })}
            />
            <label
              className="form-check-label"
              htmlFor="displayLocationCheckbox"
            >
              show home
            </label>
          </div>

          {/* <div>{JSON.stringify(location)}</div>
          <div>{JSON.stringify(options)}</div>
          <div>{JSON.stringify(center)}</div>
          <div>{JSON.stringify(zoom)}</div>
          <div>{JSON.stringify(map_latitude)}</div>
          <div>{JSON.stringify(map_longitude)}</div> */}
        </Col>
        <Col sm={9}>
          <div style={{ height: "50vh", width: "100%", position: "relative" }}>
            {results && (
              <div>
                We found {results.length} events within {options.distance} km of{" "}
                {city_name}
              </div>
            )}
            {Boolean(center) ? (
              <>
                <GoogleMapReact
                  apiKey={GOOGLE_MAPS_API_KEY}
                  //   bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                  // defaultCenter={center}
                  defaultCenter={{
                    lat: location.latitude,
                    lng: location.longitude,
                  }}
                  defaultZoom={7}
                  events={[
                    {
                      name: "onClick",
                      handler: () => {
                        console.log("set dragging?, or clicked?");
                        dispatch({ type: "USER_CLICKED" });
                      },
                    },
                    // {
                    //   name: "onClick",
                    //   handler: _onDragEnd,
                    // },
                  ]}
                  onChange={_onDragEnd}
                  // onDragEnd={_onDragEnd}
                  onGoogleApiLoaded={onGoogleApiLoaded}
                  center={center}
                  zoom={zoom}
                >
                  {mapReady && showHomeMarker && (
                    <MapMarker
                      lat={location.latitude}
                      lng={location.longitude}
                      title={"home"}
                    />
                  )}
                  {Boolean(markers) &&
                    markers.map((marker, index) => (
                      <MapMarker
                        key={`marker-${index}`}
                        type="facility"
                        lat={marker.latitude}
                        lng={marker.longitude}
                        title={marker.label}
                      />
                    ))}
                </GoogleMapReact>
                {showRedoSearch && (
                  <Button
                    className="btn btn-danger redo-search-btn"
                    onClick={() =>
                      dispatch({
                        type: "SEARCH_MAP_LOCATION",
                      })
                    }
                  >
                    redo search here
                  </Button>
                )}
              </>
            ) : Boolean(location) ? (
              <div>searching...</div>
            ) : (
              <div>retrieving location</div>
            )}
          </div>
          {Boolean(markers) && (
            <>
              <GroupByButtons />
              {groupBy === "date" && Boolean(groupedResults) && (
                <DateGroupOptions />
              )}
              <MarkersList />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Map;
