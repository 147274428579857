import React from "react";
import dayjs from "dayjs";

const DatePanel = ({ date, endDate }) => {
  const useDate = dayjs(date);
  const useEndDate = endDate ? dayjs(endDate) : null;
  const date_number = useDate.format("DD");
  const month_name = useDate.format("MMM");
  return (
    <div className="">
      <div className="badge text-bg-warning py-2 align-items-center">
        <div className="fs-6">{month_name}</div>
        <div className="fs-4">{date_number}</div>
      </div>
      {useEndDate && <div className="fw-bold ms-auto">2 DAYS</div>}
    </div>
  );
};

export default DatePanel;
