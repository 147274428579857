import React from "react";

const MapMarker = ({ type = "home", title }) => {
  const K_WIDTH = 20;
  const K_HEIGHT = 20;
  const marker_style = {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,

    border: "5px solid",
    borderColor: type === "home" ? "#82D616" : "#f44336",
    borderRadius: K_HEIGHT,
    backgroundColor: "white",
    textAlign: "center",
    color: "#3f51b5",
    fontSize: 16,
    fontWeight: "bold",
    padding: 4,
  };

  return <div style={marker_style} />;
};

export default MapMarker;
