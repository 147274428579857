import http from "./httpService";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const base_location = {
  latitude: "37.35",
  longitude: "-122.03",
  distance: "20000",
};

// get a single account
export const fetch = async (id) => {
  try {
    let result = await http.post(
      API_ENDPOINT + "/api/account/" + id,
      base_location
    );
    return result.data;
  } catch (error) {
    console.log("endpoint error", error);
    return { error: "some issue" };
  }
};

// const updateImage = async (data) => {
//   try {
//     console.log("send off image obj: ", data);
//     const endpoint = API_ENDPOINT + "/api/account/update-image";
//     console.log("send to: ", endpoint);
//     const result = await http.post(endpoint, data);

//     return result;
//   } catch (error) {
//     console.log("update image error", error);
//     return { error: "some issue" };
//   }
// };

// const updateAltImage = async (data) => {
//   try {
//     console.log("send off image obj: ", data);
//     const endpoint = API_ENDPOINT + "/api/account/update-alt-image";
//     console.log("send to: ", endpoint);
//     const result = await http.post(endpoint, data);

//     return result;
//   } catch (error) {
//     console.log("update alt image error", error);
//     return { error: "some issue" };
//   }
// };

const accountServices = { fetch };
export default accountServices; //{ fetch, updateImage, updateAltImage };
