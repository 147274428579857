import React from "react";
// import { Link } from "react-router-dom";
import { Instagram, Linkedin } from "react-bootstrap-icons";
import { getWwwRoot } from "../utils/wwwUrl";

function Footer(props) {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const wwwRoot = getWwwRoot();
  console.log("wwwRoot ", wwwRoot);

  return (
    <footer className="footer bg-dark mt-5">
      <div className="container align-items-center py-4">
        <a
          href={`${wwwRoot}/legal/privacy`}
          className="link-light text-decoration-none "
          target="_blank"
          rel="noreferrer"
        >
          Privacy
        </a>

        <a
          href={`${wwwRoot}/legal/terms`}
          className="link-light text-decoration-none ms-4"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>

        <a
          href={`${wwwRoot}/support`}
          className="link-light text-decoration-none ms-4"
          target="_blank"
          rel="noreferrer"
        >
          Support
        </a>
        <span className="float-end text-light ">
          <a
            href="https://www.instagram.com/trakdayr/"
            className="link-light me-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </a>
          <a
            href="https://www.linkedin.com/company/trakdayr/"
            className="link-light me-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin />
          </a>
          ©{getCurrentYear()}
        </span>
      </div>
    </footer>
  );
}

export default Footer;
