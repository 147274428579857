import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Stack, Image } from "react-bootstrap";
import { BoxArrowRight, Map, TelephoneOutbound } from "react-bootstrap-icons";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { fetch } from "../services/accountService";

import AccountBadge from "../components/accountBadge";
import DatePanel from "../components/datePanel";

import { Logo } from "../components";
import { extractDomain } from "../utils/wwwUrl";

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const getUrlForMap = (address) => {
  return `https://www.google.com/maps/search/${encodeURIComponent(address)}`; // target="_blank">View on Google Maps</a>
};

// const getOSMUrlForMap = ({ latitude, longitude }) => {
//   return `https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}#map=16/${latitude}/${longitude}`;
// };

const handleEventClick = (event_url) => {
  // event.preventDefault();
  // Handle the click event here (e.g., navigate to an external website)
  window.location.href = event_url; // 'https://www.example.com'; // Replace with your external URL
};

function Account(props) {
  const { id } = useParams();
  const [account, setAccount] = useState(null);

  console.log("account page: ", id);

  useEffect(() => {
    const getAccount = async () => {
      try {
        if (!id) return;
        const result = await fetch(id);
        console.log("account page - result", result);
        setAccount(result);
      } catch (err) {
        console.log("account error: ", err);
      }
    };
    getAccount();
  }, [id]);

  const AccountTabs = useCallback(
    () => (
      <Tabs>
        <TabList>
          {Boolean(account.events) && account.events.length > 0 && (
            <Tab>Events</Tab>
          )}
          {Boolean(account.tracks) && account.tracks.length > 0 && (
            <Tab>Tracks</Tab>
          )}
          {Boolean(account.sponsors) && account.sponsors.length > 0 && (
            <Tab>Sponsors</Tab>
          )}
          {Boolean(account.sponsoring) && account.sponsoring.length > 0 && (
            <Tab>Sponsoring</Tab>
          )}
        </TabList>

        {Boolean(account.events) && account.events.length > 0 && (
          <TabPanel>
            <EventsPanel events={account.events} />
          </TabPanel>
        )}
        {Boolean(account.tracks) && account.tracks.length > 0 && (
          <TabPanel>
            <TracksPanel tracks={account.tracks} />
          </TabPanel>
        )}
        {Boolean(account.sponsors) && account.sponsors.length > 0 && (
          <TabPanel>
            <SponsorsPanel sponsors={account.sponsors} />
          </TabPanel>
        )}
        {Boolean(account.sponsoring) && account.sponsoring.length > 0 && (
          <TabPanel>
            <SponsoringPanel sponsoring={account.sponsoring} />
          </TabPanel>
        )}

        {/* <TabPanel>
        <ConfigsPanel configs={track.track_configs} />
      </TabPanel> */}
      </Tabs>
    ),
    [account]
  );

  // // generic panel handler for lists
  // // bumped due to tabs issue
  // const InfoPanel = ({ title, list, Component }) => {
  //   console.log("InfoPanel writing", title);
  //   return (
  //     <>
  //       <h5>{title}</h5>
  //       {list.map((item) => (
  //         <Component item={item} key={item.id} />
  //       ))}
  //     </>
  //   );
  // };

  const InfoPanel = ({ account }) => {
    return (
      <div className="mt-3">
        {account.logo_authorized__c && (
          <>
            <Logo
              url={account.logo_url__c}
              background={account.logo_background_color__c}
              height={50}
              width={89}
              className="ms-2"
            />
            <hr className="divider" />
          </>
        )}
        {account.website && (
          <>
            <a
              className="link"
              href={account.website}
              target="_blank"
              rel="noreferrer"
            >
              <div className="d-flex justify-content-between align-items-center">
                <h5>{extractDomain(account.website)}</h5>
                <BoxArrowRight className="mx-1" />
              </div>
            </a>
            <hr className="divider" />
          </>
        )}

        {account.phone && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h5>{extractDomain(account.phone)}</h5>
              <TelephoneOutbound className="mx-1" />
            </div>
            <hr />
          </>
        )}

        <a
          className="link mb-3"
          href={getUrlForMap(
            `${account.billingstreet} ${account.billingcity} ${account.billingstate}`
          )}
          target="_blank"
          rel="noreferrer"
        >
          <div className="d-flex justify-content-between align-items-center text-decoration-none">
            <div>
              {account.billingcity}, {account.billingstate}
            </div>
            <Map className="mx-1" />
          </div>
        </a>
      </div>
    );
  };

  const Offering = ({ offering }) => {
    console.log("writing offering: ", offering);
    const icons = {
      Facility: "fa-warehouse",
      Gear: "fa-shirt",
      Manufacturer: "fa-industry",
      Organizer: "fa-tent",
      Parts: "fa-gear",
      Service: "fa-wrench",
      Vendor: "fa-store",
    };

    return (
      <div className="border border-secondary rounded px-2 me-2">
        <i className={`fas ${icons[offering.type__c]} me-1`} />
        {offering.name}
      </div>
    );
  };

  const RecordTypeOffering = ({ recordType }) => {
    console.log("writing RecordTypeOffering: ", recordType);
    const icons = {
      Gear: "fa-shirt",
      Parts: "fa-gear",
      Service: "fa-wrench",
    };

    return <Offering offering={{ name: recordType, type__c: recordType }} />;
  };

  const SponsoringPanel = ({ sponsoring }) => {
    return (
      <Row className="mt-3">
        {sponsoring.map((sponsor) => (
          <SponsorListing sponsor={sponsor} key={sponsor.id} />
        ))}
      </Row>
    );
  };

  const SponsorsPanel = ({ sponsors }) => {
    return (
      <Row className="mt-3">
        {sponsors.map((sponsor) => (
          <SponsorListing sponsor={sponsor} key={sponsor.id} />
        ))}
      </Row>
    );
  };
  const SponsorListing = ({ sponsor }) => {
    console.log("SponsorListing writing", sponsor);
    return (
      <Row className="my-1">
        <Col>
          <div className="d-flex ">
            {sponsor.logo_authorized__c && (
              <Logo
                url={sponsor.logo_url__c}
                background={sponsor.logo_background_color__c}
                height={50}
                width={89}
              />
            )}
            <div>
              <Link to={`/account/${sponsor.sfid}`} className="link">
                <h4>{sponsor.name}</h4>
              </Link>
              <div className="d-flex ">
                <RecordTypeOffering recordType={sponsor.recordtypename} />
                {sponsor.services &&
                  sponsor.services.length > 0 &&
                  sponsor.services.map((service) => (
                    <Offering
                      offering={service}
                      key={`item-${sponsor.id}-${service.id}`}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const TracksPanel = ({ tracks }) => {
    return (
      <>
        <h5>tracks</h5>
        {tracks.map((track) => {
          console.log("listing track: ", track);
          return (
            <Row>
              <Col sm={9}>
                <div className="d-flex">
                  {track.image_url__c && (
                    <Image
                      src={JSON.parse(track.image_url__c).thumb}
                      height={60}
                      width={60}
                      className="mx-2"
                    />
                  )}
                  <div>
                    <h4>{track.name}</h4>
                    <p>{track.length__c} km</p>
                  </div>
                </div>
              </Col>
              <Col sm={3}>
                <Link to={`/track/${track.sfid}`}>
                  <Button className="btn-secondary">Details</Button>
                </Link>
              </Col>
            </Row>
          );
        })}
      </>
    );
  };
  const EventsPanel = ({ events }) => {
    return (
      <Row className="mt-3 ">
        {events.map((event) => {
          console.log("listing event: ", event);
          return (
            <Row key={event.id} className="align-items-center mb-2">
              <Col sm={7}>
                <div className="d-flex">
                  <DatePanel date={event.date} />
                  <div className="ms-2">
                    <h4>{event.facility__common_name__c}</h4>
                    <div className="fw-semibold fs-6">{`${event.track__name} - ${event.config__name}`}</div>
                  </div>
                </div>
              </Col>
              <Col sm={2}>
                <h4>{USDollar.format(event.price__c)}</h4>
              </Col>
              <Col sm={2}>
                <Stack direction="horizontal" gap={1}>
                  {event.event_url__c && (
                    <Button
                      className="btn-danger d-flex align-items-center"
                      onClick={() => handleEventClick(event.event_url__c)}
                    >
                      <BoxArrowRight className="mx-1" />
                      Buy
                    </Button>
                  )}
                  <Link key={event.sfid} to={`/event/${event.sfid}`}>
                    <Button className="btn-secondary">Details</Button>
                  </Link>
                </Stack>
              </Col>
            </Row>
          );
        })}
      </Row>
    );
  };

  if (!account) return <Container>loading...</Container>;

  return (
    <Container>
      <Row>
        <Col>
          <div className="text-uppercase title_font  py-3">account listing</div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="d-flex align-items-center mb-2">
            {account.image_url__c && (
              <Image
                src={JSON.parse(account.image_url__c).thumb}
                height={90}
                width={90}
                className="mx-2"
              />
            )}
            <div>
              <div className="d-flex align-items-center">
                <h2 className="title_font">{account.name}</h2>
                {/* {account.logo_authorized__c && (
                  <Logo
                    url={account.logo_url__c}
                    background={account.logo_background_color__c}
                    height={50}
                    width={89}
                    className="ms-2"
                  />
                )} */}
              </div>
              <div className="d-flex align-items-center">
                {/* <AccountBadge account={account} /> */}
                <RecordTypeOffering recordType={account.recordtypename} />
                {account.services &&
                  account.services.length > 0 &&
                  account.services.map((service) => (
                    <Offering
                      offering={service}
                      key={`item-${account.id}-${service.id}`}
                    />
                  ))}
              </div>
            </div>
          </div>
          {/* <p>
            {account.billingcity}, {account.billingstate}{" "}
            <Map className="mx-1" />
            <a
              href={getUrlForMap(
                `${account.billingstreet} ${account.billingcity} ${account.billingstate}`
              )}
              target="_blank"
              rel="noreferrer"
            >
              Google Maps
            </a>
            {/* <a
              className="px-3"
              href={getOSMUrlForMap({
                latitude: account.billinglatitude,
                longitude: account.billinglongitude,
              })}
              target="_blank"
              rel="noreferrer"
            >
              osm
            </a></p> */}
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <AccountTabs />
          {/*           
          {Boolean(account.events) && account.events.length > 0 && (
            <EventsPanel events={account.events} />
          )}

          {Boolean(account.tracks) && account.tracks.length > 0 && (
            <TracksPanel tracks={account.tracks} />
          )}
          {Boolean(account.sponsors) && account.sponsors.length > 0 && (
            <InfoPanel
              title="Sponsors"
              list={account.sponsors}
              Component={SponsorListing}
            />
          )} */}
        </Col>
        <Col sm={4}>
          <div className="border border-secondary rounded p-2">
            <InfoPanel account={account} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Account;
