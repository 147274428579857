import { Route, Routes } from "react-router-dom";

import Footer from "./components/footer";
import Header from "./components/header";
// screens
import Account from "./screens/account";
import Event from "./screens/event";
import Map from "./screens/map";
import Track from "./screens/track";

import "./App.css";

// const DEFAULT_ZOOM = 11;

function App() {
  return (
    <div className="d-flex flex-column app-page">
      <Header />
      {/* <main
        className="container main-container flex-grow-1 main_div"
        role="main"
      > */}
      <div className=" main_div">
        <Routes>
          {/* 
        <Route path="/event/:id" element={<Event />} /> */}
          <Route path="/account/:id" element={<Account />} />
          <Route path="/event/:id" exact element={<Event />} />
          <Route path="/track/:id" exact element={<Track />} />
          <Route path="/" exact element={<Map />} />
        </Routes>
      </div>
      <Footer />
      {/* </main> */}
    </div>
  );
}

export default App;
