function removeTrailingSlash(inputString = "www.trakdayr.com") {
  if (inputString.endsWith("/")) {
    return inputString.slice(0, -1); // Remove the last character (the slash)
  }
  return inputString; // No change needed
}

const www = removeTrailingSlash(process.env.REACT_APP_WWW_ENDPOINT);
console.log("using www: ", www);

export const extractDomain = (url) => {
  // Remove http:// or https://
  url = url.replace(/(https?:\/\/)/, "");

  // Remove www.
  url = url.replace(/www\./, "");

  // Split the URL by slashes and get the first part (the domain)
  const parts = url.split("/");
  const domain = parts[0];

  return domain;
};

export const getWwwRoot = () => {
  console.log(" www", www);
  return www;
};

// export  extractDomain, getWwwRoot;
