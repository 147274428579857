import React, { useEffect, useReducer } from "react";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";

const today = dayjs(); //.add(4, "month");
// Calculate today + 2 weeks
const twoWeeksLater = today.add(2, "week");

const reducer = (state, action) => {
  console.log("SearchPanel reducer:", action.type);
  switch (action.type) {
    case "UPDATE_DISTANCE":
      return {
        ...state,
        distance: action.payload,
      };
    case "UPDATE_END_DATE":
      return {
        ...state,
        end_date: action.payload,
      };
    case "UPDATE_START_DATE":
      return {
        ...state,
        start_date: action.payload,
      };
    default:
      return state;
  }
};

const SearchPanel = ({ onChange, onSubmit }) => {
  const [search_options, dispatch] = useReducer(reducer, {
    distance: 250,
    start_date: today.format("YYYY-MM-DD"),
    end_date: twoWeeksLater.format("YYYY-MM-DD"),
  });

  const { distance, start_date, end_date } = search_options;

  useEffect(() => {
    // on load, end defaults back to map page
    onChange(search_options);
  }, [distance, start_date, end_date]);

  // const handleSearchClick = () => {
  //   console.log("search clicked with ", search_options);
  //   onChange(search_options);
  // };

  return (
    <div className="mt-2">
      <label htmlFor="start_date">start date</label>
      <input
        type="date"
        id="start_date"
        name="start_date"
        className="form-control my-2"
        min={today.format("YYYY-MM-DD")}
        value={start_date}
        onChange={(e) =>
          dispatch({
            type: "UPDATE_START_DATE",
            payload: e.currentTarget.value,
          })
        }
      />
      <label htmlFor="end_date">end date</label>
      <input
        type="date"
        id="end_date"
        name="end_date"
        className="form-control my-2"
        min={start_date}
        value={end_date}
        onChange={(e) =>
          dispatch({
            type: "UPDATE_END_DATE",
            payload: e.currentTarget.value,
          })
        }
      />

      <label htmlFor="distance" className="mb-2">
        distance (km)
      </label>
      <br />
      <input
        type="range"
        id="distance"
        name="distance"
        min="100"
        max="1000"
        value={distance}
        onChange={(e) =>
          dispatch({
            type: "UPDATE_DISTANCE",
            payload: e.currentTarget.value,
          })
        }
      />
      <div className="mx-3 mb-2">{distance}</div>
      <br />
      <Button
        className="btn btn-danger my-3"
        size="sm"
        onClick={onSubmit}
        disabled={end_date < start_date}
      >
        SEARCH
      </Button>
    </div>
  );
};

export default SearchPanel;
