import React from "react";
import { Image } from "react-bootstrap";

const Logo = ({ url, background, height, width, ...rest }) => {
  return (
    <div
      style={{ backgroundColor: "#" + background }}
      className="me-2"
      {...rest}
    >
      <Image
        src={url}
        height={height}
        width={width}
        style={{ objectFit: "contain" }}
      />
    </div>
  );
};

export default Logo;
