import http from "./httpService";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const EVENTS_ENDPOINT = "/api/trackevents";

// public event
export const fetchPublic = async (id) => {
  try {
    const use_path = API_ENDPOINT + EVENTS_ENDPOINT + "/public/" + id;
    console.log("hitting path ", use_path);
    let results = await http.get(use_path);
    return results;
  } catch (error) {
    console.log("endpoint error", error);
    return { ok: false, error: error };
  }
};

// public event
const fetchPublicWithLocation = async (id, location) => {
  try {
    const use_path = API_ENDPOINT + EVENTS_ENDPOINT + "/public/" + id;
    console.log("hitting path ", use_path);
    let results = await http.post(use_path);
    return results;
  } catch (error) {
    console.log("endpoint error", error);
    return { ok: false, error: error };
  }
};

export const fetchNear = async (options) => {
  try {
    console.log("trying to get using ", options);
    let results = await http.post(
      API_ENDPOINT + EVENTS_ENDPOINT + "/near",
      options
    );
    console.log("tracks fetchNear returns: ", results);
    return results;
  } catch (error) {
    console.log("endpoint error", error);
    return { error: "some issue" };
  }
};

const eventServices = { fetchPublic, fetchPublicWithLocation, fetchNear };
export default eventServices;
